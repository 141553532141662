/* 底部导航组件 */
<template>
	<div class="nav_footer">
		<footer>
			<div class="footer_bottom">
				<div class="footer_bottom_logo">
<!--					<a @click="$router.push('/product')"></a>
					<p>咨询：13708206115</p>-->
				</div>
<!--				<div class="footer_line"></div>-->
				<div class="footer_bottom_data">
					<dl v-for="(item, index) in footer_bottomdata" :key="index">
						<!-- <dt>{{ item.title }}</dt>-->
						<dd v-for="(item, index) in item.data" :key="index">
<!--							<a :href="item.url" target="_blank">{{ item.name }}</a>-->
						</dd>
					</dl>
				</div>
			</div>
			<div class="footer_bottom">
				<dl v-for="(item, index) in put_on_records" :key="index">
					<dd>{{ item.title }} </dd> <a :href="item.url" target="_blank">{{ item.content }}</a>
				</dl>
			</div>
		</footer>
	</div>
</template>
<script>
export default {
	name: "nav-footer",
	data() {
		return {
			put_on_records: [
				{
					/*title: "ICP备案/许可证号(ICP备案主体信息):",
					content:"鄂ICP备2022011542号",
					url: ""*/
				},
				{
					// title: "主办单位名称(ICP备案主体信息):武汉新致数字科技有限公司",
					// /*content:"武汉新致数字科技有限公司",*/
					// url: ""
				},
				{
					/*title: "ICP备案/许可证号(ICP备案网站信息):",
					content:"鄂ICP备2022011542号-1",
					url: "https://beian.miit.gov.cn/"*/
				},
				{
					/*title: "网站域名(ICP备案网站信息):",
					content:"opencom.com.cn",
					url: ""*/
				}
			],
			footer_bottomdata: [
				{
					title: "", data: [
						{
							name: '天天数链',
							url: 'https://www.tntlinking.com'
						}
					]
				},
				{
					title: "", data: [
						{
							name: '新致软件',
							url: 'https://www.newtouch.com'
						}
					]
				},
				{
					title: "", data: [
						{
							/*name: '医盟网',
							url: 'http://chisc.net/'*/
						}
					]
				},
				{
					title: "", data: [
						{
							/*name: 'Q医疗',
							url: 'http://www.qyiliao.com/'*/
						}
					]
				},
			],
		};
	},
};
</script>
<style lang="scss">
@import "../assets/scss/base.scss";
@import "../assets/scss/mixin.scss";
@import "../assets/scss/config.scss";

.nav_footer {
	background-color: #ffffff;
}

footer {
	width: 100%;
	padding: 40px 0 30px;
	width: $min-width;
	margin-right: auto;
	margin-left: auto;
	font-size: 14px;
}

.footer_bottom {
	display: flex;
	justify-content: space-between;

	dl {
		a {
			width: 100%;
			display: inline-block;
			font-size: 13px;
			color: $colorL1;

			&:hover {
				color: #1016EB;
			}
		}
	}

	.footer_bottom_logo {
		a {
			display: inline-block;
			vertical-align: middle;
			@include setsize(171px, 32px);

			&::before {
				content: "";
				display: inline-block;
				//@include setimg("/imgs/tt-logo.png");
				@include setsize(171px, 32px);
				cursor: pointer;
				//transition: all 0.3s;
			}
		}

		p {
			font-size: 14px;
			color: #202839;
			margin-top: 20px;
		}
	}

	.footer_line {
		width: 1px;
		height: 88px;
		background-color: #cccccc;
	}

	.footer_bottom_data {
		width: 820px;
		justify-content: space-between;
		display: flex;
	}

	dl {
		margin: 30px 20px;

		dt {
			font-weight: 700;
			color: $colorT1;
			margin-bottom: 20px;
		}

		dd {
			margin: 0;
			line-height: 30px;

			a {
				width: 100%;
				display: inline-block;
				font-size: 13px;
				color: $colorL1;

				&:hover {
					color: #1016EB;
				}
			}
		}
	}

	.concatwe {
		text-align: center;
		line-height: 40px;

		.concatwe_tel {
			font-size: 25px;
			color: #1016EB;
		}

		.concatwe_time {
			color: $colorL1;
			font-size: 12px;
		}

		button {
			border: 1px solid #1016EB;
			color: #1016EB;
			background: #fff;
			width: 130px;
			height: 30px;
			font-size: 13px;
			transition: all 0.5s;

			&:hover {
				color: white;
				background: #1016EB;
			}
		}

		.lookwe_one {
			font-size: 13px;
			color: $colorL1;
			margin-right: 10px;
		}

		.lookwe_two {
			&:hover {
				color: #1016EB;
			}
		}

		.lookwe_three {
			&:hover {
				color: #1016EB;
			}
		}
	}
}
</style>
